import {
  LOAD_MARKETS,
  SET_MARKETS,
  SET_MARKET_DETAIL,
  SET_MARKET_OHLC,
  GET_FAV_MARKETS,
  SET_BASE_CURR_EXCH_RATE,
  SET_QUOTE_CURR_EXCH_RATE,
} from '../actions/marketsActions'

const initialState = {
  markets: [],
  favMarkets: [],
  marketDetail: false,
  loading: false,
  ohlc: [],
  baseCurrExchRate: 1,
  quoteCurrExchRate: 1,
}

const marketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MARKETS:
      return {
        ...state,
        loading: true
      }
    case SET_MARKETS:
      return {
        ...state,
        loading: false,
        markets: action.markets,
      }
    case GET_FAV_MARKETS:
      return {
        ...state,
        favMarkets: action.favMarkets,
      }
    case SET_MARKET_DETAIL:
      return {
        ...state,
        marketDetail: action.marketDetail,
      }
    case SET_MARKET_OHLC:
      return {
        ...state,
        ohlc: action.ohlc,
      }
    case SET_BASE_CURR_EXCH_RATE:
      return {
        ...state,
        baseCurrExchRate: action.baseCurrExchRate,
      }
    case SET_QUOTE_CURR_EXCH_RATE:
      return {
        ...state,
        quoteCurrExchRate: action.quoteCurrExchRate,
      }
    default:
      return state
  }
}

export default marketsReducer
