import React, { useEffect } from 'react'
import MetaData from '../components/MetaData'
import { Container, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import { Section, Title, Text } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// import EmailForm from '../components/EmailForm/EmailForm.js'
import LogoDark from '../assets/image/svg/LsDarkLogo.inline.svg'
import LogoWhite from '../assets/image/svg/LsWhiteLogo.inline.svg'

const ComingSoon = props => {
  const { t, ready } = useTranslation(['comingSoon'], { useSuspense: false })
  const userDarkMode = useSelector(state => state.user.darkMode)
  
  useEffect(() => {
    const js = `
        (function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
        var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
        f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
        var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
        _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');
        
        var ml_account = ml('accounts', '1887272', 'q6x0d1k8b7', 'load');
      `
      const script = document.createElement('script')
      const scriptText = document.createTextNode(js)
      script.appendChild(scriptText)
      document.head.appendChild(script)
  }, [])
  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper>
        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container className="mt-5">
            {userDarkMode ? (
              <LogoWhite width="332" height="72" />
            ) : (
              <LogoDark width="332" height="72" />
            )}
            {/* <Col className="mt-5" lg="12">
              <Title color={userDarkMode ? '#FAFAFA' : 'black'}>
                {t('attention')}
              </Title>
              <Title variant="highlight" color={userDarkMode ? '#FAFAFA' : 'black'} >
                {t('ultimate')}
              </Title>
            </Col> */}
            <Col className="mt-5" lg="9">
              <Text style={{lineHeight: '27px'}} color={userDarkMode ? '#FAFAFA' : 'black'}>
                Thank you for your interest, please enter your e-mail address to be notified of future updates:
              </Text>
            </Col>
            <Col className="mt-3" lg="7">
              {/* <EmailForm formType="mailLite" /> */}
              <div className="ml-form-embed"
                data-account="1887272:q6x0d1k8b7"
                data-form="4573645:s9a7v2">
              </div>
            </Col>
            <Col className="mt-3" lg="7">
              <Text
                variant="small"
                color={
                  userDarkMode
                    ? 'rgba(255, 255, 255, 0.56)'
                    : 'rgba(0, 0, 0, 0.56)'
                }
              >
                {t('byClicking')}
              </Text>
            </Col>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default ComingSoon
