import {
  LOAD_CURRENCIES,
  LOAD_CURR_MARKETS,
  SET_CURRENCIES,
  SET_EXCH_CURRS,
  SET_BTC_EXCH_RATE,
  SET_CURRENCY_DETAIL,
  SET_CURR_OHLC,
  SET_CURR_MARKETS,
  SET_TABLE_EXCH_RATE,
  SET_DEFAULT_MARKET,
} from '../actions/currenciesActions'

const initialState = {
  currencies: [],
  exchCurrs: [],
  loading: false,
  btcExchRate: 1,
  tableExchRate: 1,
  currency: false,
  ohlc: [],
  ohlcQuoteCurr: '',
  currMarkets: [],
  loadingCurrMarkets: false,
  defaultMarket: {},
}

const currenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CURRENCIES:
      return {
        ...state,
        loading: true
      }
    case SET_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies,
        loading: false
      }
    case SET_EXCH_CURRS:
      return {
        ...state,
        exchCurrs: action.exchCurrs,
        loading: false
      }
    case SET_BTC_EXCH_RATE:
      return {
        ...state,
        btcExchRate: action.btcExchRate,
      }
    case SET_TABLE_EXCH_RATE:
      return {
        ...state,
        tableExchRate: action.tableExchRate,
      }
    case SET_CURRENCY_DETAIL:
      return {
        ...state,
        currency: action.currency,
      }
    case LOAD_CURR_MARKETS:
      return {
        ...state,
        loadingCurrMarkets: true
      }
    case SET_CURR_MARKETS:
      return {
        ...state,
        currMarkets: action.currMarkets,
        loadingCurrMarkets: false,
      }
    case SET_CURR_OHLC:
      return {
        ...state,
        ohlc: action.ohlc,
        ohlcQuoteCurr: action.ohlcQuoteCurr,
      }
    case SET_DEFAULT_MARKET:
      return {
        ...state,
        defaultMarket: action.defaultMarket,
      }
    default:
      return state
  }
}

export default currenciesReducer
