import MarketModel from '../../models/MarketModel'
import ExchMarketDataModel from '../../models/ExchMarketDataModel'
import OHLCModel from '../../models/OHLCModel'
import { buildApiUrl } from '../../utils/helperFn'
export const SET_MARKETS = 'SET_MARKETS'
export const SET_MARKET_DETAIL = 'SET_MARKET_DETAIL'
export const SET_MARKET_OHLC = 'SET_MARKET_OHLC'
export const GET_FAV_MARKETS = 'GET_FAV_MARKETS'
export const SET_BASE_CURR_EXCH_RATE = 'SET_BASE_CURR_EXCH_RATE'
export const SET_QUOTE_CURR_EXCH_RATE = 'SET_QUOTE_CURR_EXCH_RATE'
export const LOAD_MARKETS = 'LOAD_MARKETS'

export const fetchMarkets = (
  searchTerm,
  sortBy,
  sortOrder,
  pageSize,
  pageNumber,
  miniChartRange
) => {
  let endpoint = buildApiUrl('/public/markets/market-summaries', {
    SearchTerm: searchTerm,
    Sort: sortBy,
    SortOrder: sortOrder,
    PageSize: pageSize,
    PageNumber: pageNumber,
    Range: miniChartRange,
  })

  return async (dispatch, getState) => {
    dispatch({ type: LOAD_MARKETS })
    const response = await fetch(endpoint)

    let resData = await response.json()
    const loadedData = []
    for (const market in resData.result) {
      if (resData.result[market].exchId !== 91) {
        loadedData.push(
          new MarketModel(
            resData.result[market].exchmktId,
            resData.result[market].displayName,
            resData.result[market].exchId,
            resData.result[market].exchName,
            resData.result[market].exchCode,
            resData.result[market].marketId,
            resData.result[market].marketName,
            resData.result[market].displayBaseCurrencyCode !== null ? resData.result[market].displayBaseCurrencyCode : resData.result[market].baseCurrencyCode,
            resData.result[market].displayQuoteCurrencyCode !== null ? resData.result[market].displayQuoteCurrencyCode : resData.result[market].quoteCurrencyCode,
            resData.result[market].baseIsFiat,
            resData.result[market].quoteIsFiat,
            resData.result[market].displayBaseCurrencyName !== null ? resData.result[market].displayBaseCurrencyName : resData.result[market].baseCurrencyName,
            resData.result[market].displayQuoteCurrencyName !== null ? resData.result[market].displayQuoteCurrencyName : resData.result[market].quoteCurrencyName,
            resData.result[market].quantityPrecision,
            resData.result[market].pricePrecision,
            resData.result[market].lastTradePrice,
            resData.result[market].volume24,
            resData.result[market].miniChartData,
            resData.result[market].exchSlug,
            resData.result[market].volume24Btc,
            resData.result[market].btcPrice,
            resData.result[market].percentChange,
            resData.totalRecords
          )
        )
      }
    }
    // console.log('Loaded markets data OK', loadedData);
    dispatch({
      type: SET_MARKETS,
      markets: loadedData,
    })
  }
}

export const fetchFavMarkets = (
  searchTerm,
  sortBy,
  sortOrder,
  pageSize,
  pageNumber,
  miniChartRange,
  exchMarketIds
) => {
  let endpoint = buildApiUrl('/public/markets/market-summaries', {
    SearchTerm: searchTerm,
    Sort: sortBy,
    SortOrder: sortOrder,
    PageSize: pageSize,
    PageNumber: pageNumber,
    Range: miniChartRange,
    exchMarketIds: exchMarketIds,
  })

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    const loadedData = []
    for (const market in resData.result) {
      loadedData.push(
        new MarketModel(
          resData.result[market].exchmktId,
          resData.result[market].displayName,
          resData.result[market].exchId,
          resData.result[market].exchName,
          resData.result[market].exchCode,
          resData.result[market].marketId,
          resData.result[market].marketName,
          resData.result[market].baseCurrencyCode,
          resData.result[market].quoteCurrencyCode,
          resData.result[market].baseIsFiat,
          resData.result[market].quoteIsFiat,
          resData.result[market].baseCurrencyName,
          resData.result[market].quoteCurrencyName,
          resData.result[market].quantityPrecision,
          resData.result[market].pricePrecision,
          resData.result[market].lastTradePrice,
          resData.result[market].volume24,
          resData.result[market].miniChartData,
          resData.result[market].exchSlug,
          resData.result[market].volume24Btc,
          resData.result[market].btcPrice,
          resData.result[market].percentChange,
          resData.totalRecords
        )
      )
    }
    // console.log('Loaded FAV markets', loadedData);
    dispatch({
      type: GET_FAV_MARKETS,
      favMarkets: loadedData,
    })
  }
}

export const fetchMarketDetail = (exchSlug, baseCurrCode, quoteCurrCode) => {
  let endpoint = buildApiUrl(
    `/public/markets/${exchSlug}/${baseCurrCode}/${quoteCurrCode}/market-summaries`
  )

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()

    // console.log('RESDATA', resData);

    // const marketsArray = Object.keys(resData.result);

    let loadedMarket
    // let nextMarket;
    // let prevMarket;

    // const currentMarket = marketsArray.indexOf(marketName);
    // currentMarket >= 0 && currentMarket < marketsArray.length - 1 ? nextMarket = marketsArray[currentMarket + 1] : nextMarket = '';
    // currentMarket > 0 && currentMarket < marketsArray.length ? prevMarket = marketsArray[currentMarket - 1] : prevMarket = '';

    loadedMarket = new ExchMarketDataModel(
      resData.result.data.exchmktId,
      resData.result.data.displayName,
      resData.result.data.exchId,
      resData.result.data.exchName,
      resData.result.data.exchCode,
      resData.result.data.marketId,
      resData.result.data.marketName,
      resData.result.data.baseCurrencyCode,
      resData.result.data.quoteCurrencyCode,
      resData.result.data.baseIsFiat,
      resData.result.data.quoteIsFiat,
      resData.result.data.baseCurrencyName,
      resData.result.data.quoteCurrencyName,
      resData.result.data.quantityPrecision,
      resData.result.data.pricePrecision,
      resData.result.data.lastTradePrice,
      resData.result.data.volume24Btc,
      resData.result.data.candles,
      resData.result.prevMarketName,
      resData.result.prevDisplayName,
      resData.result.nextMarketName,
      resData.result.nextDisplayName,
      resData.result.nextSlug,
      resData.result.prevSlug,
      resData.result.nextSlugName,
      resData.result.prevSlugName
    )

    // console.log('Loaded Exchange Market OK', loadedMarket);
    dispatch({
      type: SET_MARKET_DETAIL,
      marketDetail: loadedMarket,
    })
  }
}

export const fetchMarketOHLC = (exchCode, marketName) => {
  let endpoint = buildApiUrl(
    `/public/exchanges/${exchCode}/markets/${marketName}/ohlc/all`
  )

  const loadedOhlc = []
  let reversedMktName = marketName.split('/').reverse().join('/')

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    // console.log('RESDATA', resData);

    if (resData.success === false) {
      endpoint = buildApiUrl(
        `/public/exchanges/${exchCode}/markets/${reversedMktName}/ohlc/all`
      )

      const response = await fetch(endpoint)
      let resData = await response.json()
      // console.log('RESDATA2', resData);
      for (const item in resData.result) {
        loadedOhlc.push(
          new OHLCModel(
            resData.result[item].open,
            resData.result[item].high,
            resData.result[item].low,
            resData.result[item].close,
            resData.result[item].volume,
            resData.result[item].startTime,
            resData.result[item].endTime
          )
        )
      }
    }

    for (const item in resData.result) {
      loadedOhlc.push(
        new OHLCModel(
          resData.result[item].open,
          resData.result[item].high,
          resData.result[item].low,
          resData.result[item].close,
          resData.result[item].volume,
          resData.result[item].startTime,
          resData.result[item].endTime
        )
      )
    }
    loadedOhlc.reverse()
    // console.log('OHLC', loadedOhlc);
    dispatch({
      type: SET_MARKET_OHLC,
      ohlc: loadedOhlc,
    })
  }
}

export const fetchBaseCurrExchRate = (baseCurr, userCurrency) => {
  let endpoint = buildApiUrl(`/public/convert/${baseCurr}/${userCurrency}`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    let baseCurrExchRate
    baseCurrExchRate = resData.result

    dispatch({
      type: SET_BASE_CURR_EXCH_RATE,
      baseCurrExchRate: baseCurrExchRate,
    })
  }
}

export const fetchQuoteCurrExchRate = (quoteCurr, userCurrency) => {
  let endpoint = buildApiUrl(`/public/convert/${quoteCurr}/${userCurrency}`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    let quoteCurrExchRate
    quoteCurrExchRate = resData.result

    dispatch({
      type: SET_QUOTE_CURR_EXCH_RATE,
      quoteCurrExchRate: quoteCurrExchRate,
    })
  }
}
