class ExchMarket {
  constructor(
    exchmktId,
    displayName,
    exchId,
    exchName,
    exchCode,
    marketId,
    marketName,
    baseCurrencyCode,
    quoteCurrencyCode,
    baseIsFiat,
    quoteIsFiat,
    baseCurrencyName,
    quoteCurrencyName,
    quantityPrecision,
    pricePrecision,
    lastTradePrice,
    volume24,
    miniChartData,
    exchSlug,
    volume24Btc,
    btcPrice,
    percentChange,
  ) {
    this.exchmktId = typeof exchmktId === 'undefined' ? '' : exchmktId
    this.displayName = typeof displayName === 'undefined' ? '' : displayName
    this.exchId = typeof exchId === 'undefined' ? '' : exchId
    this.exchName = typeof exchName === 'undefined' ? '' : exchName
    this.exchCode = typeof exchCode === 'undefined' ? '' : exchCode
    this.marketId = typeof marketId === 'undefined' ? '' : marketId
    this.marketName = typeof marketName === 'undefined' ? '' : marketName
    this.baseCurrencyCode =
      typeof baseCurrencyCode === 'undefined' ? '' : baseCurrencyCode
    this.quoteCurrencyCode =
      typeof quoteCurrencyCode === 'undefined' ? '' : quoteCurrencyCode
    this.baseIsFiat = typeof baseIsFiat === 'undefined' ? '' : baseIsFiat
    this.quoteIsFiat = typeof quoteIsFiat === 'undefined' ? '' : quoteIsFiat
    this.baseCurrencyName =
      typeof baseCurrencyName === 'undefined' ? '' : baseCurrencyName
    this.quoteCurrencyName =
      typeof quoteCurrencyName === 'undefined' ? '' : quoteCurrencyName
    this.quantityPrecision =
      typeof quantityPrecision === 'undefined' ? '' : quantityPrecision
    this.pricePrecision =
      typeof pricePrecision === 'undefined' ? '' : pricePrecision
    this.lastTradePrice =
      typeof lastTradePrice === 'undefined' ? '' : lastTradePrice
    this.volume24 = typeof volume24 === 'undefined' ? '' : volume24
    this.miniChartData =
      typeof miniChartData === 'undefined' ? '' : miniChartData
    this.exchSlug = typeof exchSlug === 'undefined' ? '' : exchSlug
    this.volume24Btc = typeof volume24Btc === 'undefined' ? '' : volume24Btc
    this.btcPrice = typeof btcPrice === 'undefined' ? '' : btcPrice
    this.percentChange =
      typeof percentChange === 'undefined' ? '' : percentChange
  }
}

export default ExchMarket
