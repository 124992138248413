import defaultImg from '../../assets/image/meta/default.png'
import aboutImg from '../../assets/image/meta/about.png'
import exchangesImg from '../../assets/image/meta/exchanges.png'
import platformImg from '../../assets/image/meta/platform.png'
import portfolioMgmtImg from '../../assets/image/meta/portfolio-management.png'
import pricingImg from '../../assets/image/meta/pricing.png'


const metaConfig = (page, param) => {
    const customParam = param || "";
    const BASE_URL = process.env.LS_URL;

    const metaContent = {

        default: {
            title_tag : `Trade Crypto with no added fees - Lightspeed Crypto`,
            meta_title: `Trade Crypto with no added fees - Lightspeed Crypto`,
            meta_description: `Lightspeed Crypto offers advanced trading and charting tools to trade Bitcoin, Ethereum, and other cryptocurrencies at industry leading prices`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Trade Crypto with no added fees - Lightspeed Crypto`,
            meta_og_description: `Lightspeed Crypto offers advanced trading and charting tools to trade Bitcoin, Ethereum, and other cryptocurrencies at industry leading prices`,
            meta_og_image: `${BASE_URL}${defaultImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Trade Crypto with no added fees - Lightspeed Crypto`,
            meta_tw_description: `Lightspeed Crypto offers advanced trading and charting tools to trade Bitcoin, Ethereum, and other cryptocurrencies at industry leading prices`,
            meta_tw_image: `${BASE_URL}${defaultImg}`,
            en_page_url: `${BASE_URL}/en`,
            es_page_url: `${BASE_URL}/es`
            
        },

        whyUs: {
            title_tag : `Features & tools - Lightspeed Crypto`,
            meta_title: `Features & tools - Lightspeed Crypto`,
            meta_description: `Trade Bitcoin, Ethereum, and other cryptos at industry leading prices and manage multiple portfolios across different exchanges from one central location`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Features & tools - Lightspeed Crypto`,
            meta_og_description: `Trade Bitcoin, Ethereum, and other cryptos at industry leading prices and manage multiple portfolios across different exchanges from one central location `,
            meta_og_image: `${BASE_URL}${defaultImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Features & tools - Lightspeed Crypto`,
            meta_tw_description: `Trade Bitcoin, Ethereum, and other cryptos at industry leading prices and manage multiple portfolios across different exchanges from one central location `,
            meta_tw_image: `${BASE_URL}${defaultImg}`,
            en_page_url: `${BASE_URL}/en/why-us`,
            es_page_url: `${BASE_URL}/es/why-us`
        },

        platform: {
            title_tag : `Trading platform features & tools - Lightspeed Crypto`,
            meta_title: `Trading platform features & tools - Lightspeed Crypto`,
            meta_description: `Trade Bitcoin & Ethereum at industry-leading prices when you trade with Lightspeed Crypto. Execute trades instantly and take advantage of our deep liquidity`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Trading platform features & tools - Lightspeed Crypto`,
            meta_og_description: `Trade Bitcoin & Ethereum at industry-leading prices when you trade with Lightspeed Crypto. Execute trades instantly and take advantage of our deep liquidity`,
            meta_og_image: `${BASE_URL}${platformImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Trading platform features & tools - Lightspeed Crypto`,
            meta_tw_description: `Trade Bitcoin & Ethereum at industry-leading prices when you trade with Lightspeed Crypto. Execute trades instantly and take advantage of our deep liquidity`,
            meta_tw_image: `${BASE_URL}${platformImg}`,
            en_page_url: `${BASE_URL}/en/platform`,
            es_page_url: `${BASE_URL}/es/platform`
        },

        portfolioManagement: {
            title_tag : `Portfolio management across multiple exchanges - Lightspeed Crypto`,
            meta_title: `Portfolio management across multiple exchanges - Lightspeed Crypto`,
            meta_description: `Manage multiple portfolios across different exchanges from one central location. Connect over 45 exchanges and manage 5000 different cryptocurrencies`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Portfolio management across multiple exchanges - Lightspeed Crypto`,
            meta_og_description: `Manage multiple portfolios across different exchanges from one central location. Connect over 45 exchanges and manage 5000 different cryptocurrencies`,
            meta_og_image: `${BASE_URL}${portfolioMgmtImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Portfolio management across multiple exchanges - Lightspeed Crypto`,
            meta_tw_description: `Manage multiple portfolios across different exchanges from one central location. Connect over 45 exchanges and manage 5000 different cryptocurrencies`,
            meta_tw_image: `${BASE_URL}${portfolioMgmtImg}`,
            en_page_url: `${BASE_URL}/en/portfolio-management`,
            es_page_url: `${BASE_URL}/es/portfolio-management`
        },

        pricing: {
            title_tag : `Pricing information - Lightspeed Crypto`,
            meta_title: `Pricing information - Lightspeed Crypto`,
            meta_description: `Trade Bitcoin & Ethereum at industry-leading prices. No additional fees with our transparent pricing. Never overpay for trading crypto again`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Pricing information - Lightspeed Crypto`,
            meta_og_description: `Trade Bitcoin & Ethereum at industry-leading prices. No additional fees with our transparent pricing. Never overpay for trading crypto again`,
            meta_og_image: `${BASE_URL}${pricingImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Pricing information - Lightspeed Crypto`,
            meta_tw_description: `Trade Bitcoin & Ethereum at industry-leading prices. No additional fees with our transparent pricing. Never overpay for trading crypto again`,
            meta_tw_image: `${BASE_URL}${pricingImg}`,
            en_page_url: `${BASE_URL}/en/pricing`,
            es_page_url: `${BASE_URL}/es/pricing`
        },

        exchanges: {
            title_tag : `Top Crypto Exchanges Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_title: `Top Crypto Exchanges Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Top Crypto Exchanges Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_og_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
            meta_og_image: `${BASE_URL}${exchangesImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Top Crypto Exchanges Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_tw_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
            meta_tw_image: `${BASE_URL}${exchangesImg}`,
            en_page_url: `${BASE_URL}/en/exchanges`,
            es_page_url: `${BASE_URL}/es/exchanges`
        },

        supportedExchanges: {
            title_tag : `Supported exchanges & features - Lightspeed Crypto`,
            meta_title: `Supported exchanges & features - Lightspeed Crypto`,
            meta_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Supported exchanges & features - Lightspeed Crypto`,
            meta_og_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
            meta_og_image: `${BASE_URL}${exchangesImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Supported exchanges & features - Lightspeed Crypto`,
            meta_tw_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
            meta_tw_image: `${BASE_URL}${exchangesImg}`,
            en_page_url: `${BASE_URL}/en/supported-exchanges`,
            es_page_url: `${BASE_URL}/es/supported-exchanges`
        },

        exchSlug: {
            title_tag : `${customParam.exchName} Markets, Cryptocurrencies, Trade Volume & FAQs - Lightspeed Crypto`,
            meta_title: `${customParam.exchName} Markets, Cryptocurrencies, Trade Volume & FAQs - Lightspeed Crypto`,
            meta_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `${customParam.exchName} Markets, Cryptocurrencies, Trade Volume & FAQs - Lightspeed Crypto`,
            meta_og_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
            meta_og_image: `${BASE_URL}${exchangesImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `${customParam.exchName} Markets, Cryptocurrencies, Trade Volume & FAQs - Lightspeed Crypto`,
            meta_tw_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
            meta_tw_image: `${BASE_URL}${exchangesImg}`,
            en_page_url: `${BASE_URL}/en/exchanges/${customParam.locationExchSlug}`,
            es_page_url: `${BASE_URL}/es/exchanges/${customParam.locationExchSlug}`
        },

        cryptocurrencies: {
            title_tag : `Top Cryptocurrencies Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_title: `Top Cryptocurrencies Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Top Cryptocurrencies Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_og_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,
            meta_og_image: `${BASE_URL}${defaultImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Top Cryptocurrencies Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_tw_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,
            meta_tw_image: `${BASE_URL}${defaultImg}`,
            en_page_url: `${BASE_URL}/en/cryptocurrencies`,
            es_page_url: `${BASE_URL}/es/cryptocurrencies`
        },

        currSlug: {
            title_tag : `${customParam.currName} (${customParam.code}) Price Chart & Market Cap - Lightspeed Crypto`,
            meta_title: `${customParam.currName} (${customParam.code}) Price Chart & Market Cap - Lightspeed Crypto`,
            meta_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `${customParam.currName} (${customParam.code}) Price Chart & Market Cap - Lightspeed Crypto`,
            meta_og_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,
            meta_og_image: `${BASE_URL}${defaultImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `${customParam.currName} (${customParam.code}) Price Chart & Market Cap - Lightspeed Crypto`,
            meta_tw_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,
            meta_tw_image: `${BASE_URL}${defaultImg}`,
            en_page_url: `${BASE_URL}/en/cryptocurrencies/${customParam.locationCurrSlug}`,
            es_page_url: `${BASE_URL}/es/cryptocurrencies/${customParam.locationCurrSlug}`
        },

        markets: {
            title_tag : `Top Crypto Markets Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_title: `Top Crypto Markets Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_description: `Trade Bitcoin and Ethereum direct on Lightspeed Crypto and view other markets available to trade via our innovative external exchange connector technology`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Top Crypto Markets Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_og_description: `Trade Bitcoin and Ethereum direct on Lightspeed Crypto and view other markets available to trade via our innovative external exchange connector technology`,
            meta_og_image: `${BASE_URL}${defaultImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Top Crypto Markets Ranked By Popularity & Volume - Lightspeed Crypto`,
            meta_tw_description: `Trade Bitcoin and Ethereum direct on Lightspeed Crypto and view other markets available to trade via our innovative external exchange connector technology`,
            meta_tw_image: `${BASE_URL}${defaultImg}`,
            en_page_url: `${BASE_URL}/en/markets`,
            es_page_url: `${BASE_URL}/es/markets`
        },

        marketSlug: {
            title_tag : `${customParam.exchName} ${customParam.marketName} Price Chart & Volume - Lightspeed Crypto`,
            meta_title: `${customParam.exchName} ${customParam.marketName} Price Chart & Volume - Lightspeed Crypto`,
            meta_description: `Trade Bitcoin and Ethereum direct on Lightspeed Crypto and view other markets available to trade via our innovative external exchange connector technology`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `${customParam.exchName} ${customParam.marketName} Price Chart & Volume - Lightspeed Crypto`,
            meta_og_description: `Trade Bitcoin and Ethereum direct on Lightspeed Crypto and view other markets available to trade via our innovative external exchange connector technology`,
            meta_og_image: `${BASE_URL}${defaultImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `${customParam.exchName} ${customParam.marketName} Price Chart & Volume - Lightspeed Crypto`,
            meta_tw_description: `Trade Bitcoin and Ethereum direct on Lightspeed Crypto and view other markets available to trade via our innovative external exchange connector technology`,
            meta_tw_image: `${BASE_URL}${defaultImg}`,
            en_page_url: `${BASE_URL}/en/markets/${customParam.locationExchSlug}/${customParam.marketName}`,
            es_page_url: `${BASE_URL}/es/markets/${customParam.locationExchSlug}/${customParam.marketName}`
        },

        security: {
            title_tag : `Security information - Lightspeed Crypto`,
            meta_title: `Security information - Lightspeed Crypto`,
            meta_description: `Lightspeed Crypto works diligently to keep your crypto secure. Our multi-layered security protocols ensure your funds are always protected`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Security information - Lightspeed Crypto`,
            meta_og_description: `Lightspeed Crypto works diligently to keep your crypto secure. Our multi-layered security protocols ensure your funds are always protected`,
            meta_og_image: `${BASE_URL}${defaultImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Security information - Lightspeed Crypto`,
            meta_tw_description: `Lightspeed Crypto works diligently to keep your crypto secure. Our multi-layered security protocols ensure your funds are always protected`,
            meta_tw_image: `${BASE_URL}${defaultImg}`,
            en_page_url: `${BASE_URL}/en/security`,
            es_page_url: `${BASE_URL}/es/security`
        },

        support: {
            title_tag : `Contact, Support & FAQs - Lightspeed Crypto`,
            meta_title: `Contact, Support & FAQs - Lightspeed Crypto`,
            meta_description: `Lightspeed Crypto provides comprehensive FAQs and support. Schedule a call or send us a message anytime. Our client experience team is here to help`,

            meta_og_type: `website`,
            meta_og_url: `${BASE_URL}`,
            meta_og_title: `Contact, Support & FAQs - Lightspeed Crypto`,
            meta_og_description: `Lightspeed Crypto provides comprehensive FAQs and support. Schedule a call or send us a message anytime. Our client experience team is here to help`,
            meta_og_image: `${BASE_URL}${defaultImg}`,
            
            meta_tw_card: `summary_large_image`,
            meta_tw_url: `${BASE_URL}`,
            meta_tw_title: `Contact, Support & FAQs - Lightspeed Crypto`,
            meta_tw_description: `Lightspeed Crypto provides comprehensive FAQs and support. Schedule a call or send us a message anytime. Our client experience team is here to help`,
            meta_tw_image: `${BASE_URL}${defaultImg}`,
            en_page_url: `${BASE_URL}/en/support`,
            es_page_url: `${BASE_URL}/es/support`
        },
    }

    return metaContent[page];
}

export default metaConfig;
