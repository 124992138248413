exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-leadership-js": () => import("./../../../src/pages/about/leadership.js" /* webpackChunkName: "component---src-pages-about-leadership-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-balances-js": () => import("./../../../src/pages/balances.js" /* webpackChunkName: "component---src-pages-balances-js" */),
  "component---src-pages-coinigy-partnership-js": () => import("./../../../src/pages/coinigy-partnership.js" /* webpackChunkName: "component---src-pages-coinigy-partnership-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-company-contact-js": () => import("./../../../src/pages/company/contact.js" /* webpackChunkName: "component---src-pages-company-contact-js" */),
  "component---src-pages-company-partnerships-js": () => import("./../../../src/pages/company/partnerships.js" /* webpackChunkName: "component---src-pages-company-partnerships-js" */),
  "component---src-pages-company-referrals-js": () => import("./../../../src/pages/company/referrals.js" /* webpackChunkName: "component---src-pages-company-referrals-js" */),
  "component---src-pages-company-testimonials-js": () => import("./../../../src/pages/company/testimonials.js" /* webpackChunkName: "component---src-pages-company-testimonials-js" */),
  "component---src-pages-cryptocurrencies-curr-slug-js": () => import("./../../../src/pages/cryptocurrencies/[currSlug].js" /* webpackChunkName: "component---src-pages-cryptocurrencies-curr-slug-js" */),
  "component---src-pages-cryptocurrencies-js": () => import("./../../../src/pages/cryptocurrencies.js" /* webpackChunkName: "component---src-pages-cryptocurrencies-js" */),
  "component---src-pages-exchanges-exch-slug-js": () => import("./../../../src/pages/exchanges/[exchSlug].js" /* webpackChunkName: "component---src-pages-exchanges-exch-slug-js" */),
  "component---src-pages-exchanges-js": () => import("./../../../src/pages/exchanges.js" /* webpackChunkName: "component---src-pages-exchanges-js" */),
  "component---src-pages-faq-section-js": () => import("./../../../src/pages/faqSection.js" /* webpackChunkName: "component---src-pages-faq-section-js" */),
  "component---src-pages-hello-crypto-js": () => import("./../../../src/pages/hello-crypto.js" /* webpackChunkName: "component---src-pages-hello-crypto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-better-deals-major-exchanges-js": () => import("./../../../src/pages/lp/better-deals-major-exchanges.js" /* webpackChunkName: "component---src-pages-lp-better-deals-major-exchanges-js" */),
  "component---src-pages-lp-save-on-every-trade-js": () => import("./../../../src/pages/lp/save-on-every-trade.js" /* webpackChunkName: "component---src-pages-lp-save-on-every-trade-js" */),
  "component---src-pages-lp-trade-with-precision-js": () => import("./../../../src/pages/lp/trade-with-precision.js" /* webpackChunkName: "component---src-pages-lp-trade-with-precision-js" */),
  "component---src-pages-lp-trade-with-transparency-js": () => import("./../../../src/pages/lp/trade-with-transparency.js" /* webpackChunkName: "component---src-pages-lp-trade-with-transparency-js" */),
  "component---src-pages-markets-exch-name-base-curr-market-slug-js": () => import("./../../../src/pages/markets/[exchName]/[baseCurr]/[marketSlug].js" /* webpackChunkName: "component---src-pages-markets-exch-name-base-curr-market-slug-js" */),
  "component---src-pages-markets-js": () => import("./../../../src/pages/markets.js" /* webpackChunkName: "component---src-pages-markets-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-portfolio-management-js": () => import("./../../../src/pages/portfolio-management.js" /* webpackChunkName: "component---src-pages-portfolio-management-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-security-security-practices-js": () => import("./../../../src/pages/security/security-practices.js" /* webpackChunkName: "component---src-pages-security-security-practices-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-supported-exchanges-js": () => import("./../../../src/pages/supported-exchanges.js" /* webpackChunkName: "component---src-pages-supported-exchanges-js" */),
  "component---src-pages-supporting-documents-js": () => import("./../../../src/pages/supporting-documents.js" /* webpackChunkName: "component---src-pages-supporting-documents-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-testimonials-bitwala-js": () => import("./../../../src/pages/testimonials/bitwala.js" /* webpackChunkName: "component---src-pages-testimonials-bitwala-js" */),
  "component---src-pages-testimonials-intelsath-js": () => import("./../../../src/pages/testimonials/intelsath.js" /* webpackChunkName: "component---src-pages-testimonials-intelsath-js" */),
  "component---src-pages-trade-overview-js": () => import("./../../../src/pages/trade/overview.js" /* webpackChunkName: "component---src-pages-trade-overview-js" */),
  "component---src-pages-trade-vip-program-js": () => import("./../../../src/pages/trade/vip-program.js" /* webpackChunkName: "component---src-pages-trade-vip-program-js" */),
  "component---src-pages-trade-wallet-js": () => import("./../../../src/pages/trade/wallet.js" /* webpackChunkName: "component---src-pages-trade-wallet-js" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/why-us.js" /* webpackChunkName: "component---src-pages-why-us-js" */)
}

